import Section from "../components/Section";
import WileyLink from "../components/WileyLink";

function Clients() {
  return (
      <Section
        breadcrumb="Clients"
        header="Clients"
        id="clients"
      >

        <div className="mt-8 mb-12 text-left">
          <img src="./img/Wiley_Logo_white.png" className="w-[290px]" alt="Wiley"/>
        </div>
        <div
          className="mt-4">
          <div className="mb-2 mt-4">

            <div className="mb-4">
              <WileyLink /> is an American multinational publishing company founded in 1807 that focuses on academic publishing and instructional materials. The company produces books, journals, and encyclopedias in printed and electronic formats, as well as online products and services, training, and educational materials for undergraduate, graduate, and continuing education students.
            </div>
          </div>
        </div>

        <div className="mb-2 w-full flex justify-center">
          <ul className="list-disc md:ml-8">
            <li><a href="https://onlinelibrary.wiley.com/" rel="noreferrer" target='_blank'>https://onlinelibrary.wiley.com/</a></li>
            <li><a href="https://oable.org/" rel="noreferrer" target='_blank'>https://oable.org/</a></li>
            <li><a href="https://www.cochranelibrary.com/" rel="noreferrer" target='_blank'>https://www.cochranelibrary.com/</a></li>
            <li><a href="https://www.wiley-vch.de/de/" rel="noreferrer" target='_blank'>https://www.wiley-vch.de/de/</a></li>
            <li><a href="https://authorservices.wiley.com/" rel="noreferrer" target='_blank'>https://authorservices.wiley.com/</a></li>
            <li><a href="https://www.wileyplus.com/" rel="noreferrer" target='_blank'>https://www.wileyplus.com/</a></li>
            <li><a href="https://www.efficientlearning.com/" rel="noreferrer" target='_blank'>https://www.efficientlearning.com/</a></li>
          </ul>
        </div>

        <div className="mb-2">
          ROMALEX RND is a key contractor for Wiley.
        </div>
      </Section>
  );
}

export default Clients;

