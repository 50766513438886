import './styles/main.scss';
import {Link} from 'react-router-dom';
import menu from './data/menu';
import {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'

// import MenuWithScroll from './components/MenuWithScroll'

function Layout({children}) {
  const [scrollTop, setScrollTop] = useState(0);
  const [showLogo, setShowLogo] = useState(0);

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    setShowLogo(scrollTop > document.getElementById('about')?.offsetTop - 500)
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const location = useLocation();
  const [menuVisible, setMenu] = useState(false)
  const toggleMenu = () => setMenu(!menuVisible)
  const hideMenu = () => setMenu(false)

  useEffect(() => {
    if (location.pathname === "/") {
      window.scrollTo({top: 0, behavior: 'smooth'});
    } else {
      const yOffset = -140;
      const element = document.getElementById(location.pathname.replace("/", ""));
      if (element) {
        const y = element.offsetTop + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    }


  }, [location]);

  return (
    <div className="flex w-full h-full flex-col bg-rdarkerblue">
      <nav className="w-full fixed z-10">
        <div className="flex justify-between items-center w-full h-[88px] absolute bg-rdarkblue full-menu__container
          lg:justify-center
          ">

          <div className="absolute md:w-[63%]">
            <div className={`transition-opacity duration-300 ml-[15px] lg:ml-[-6px]`} style={{opacity: showLogo ? 100 : 0}}>
              <Link className="block w-[60px]" to='/'><img src="/img/logo.svg" className="w-[60px]" alt='Mini logo'/></Link>
            </div>
          </div>

          <div className="flex items-center z-20">
            <div className="hidden lg:flex space-x-1">
              {menu.map(({title, route}) => (<Link
                  to={route}
                  className={`py-4 px-2 text-white whitespace-nowrap duration-200 transition-colors hover:text-rorange
                      
                      ${location.pathname === route ? "text-rorange" : ""}`}
                >
                  {title}
                </Link>
              ))}
              {/*<MenuWithScroll />*/}
            </div>
          </div>

          <div className="lg:hidden mr-5 z-20">
            <button className="outline-none mobile-menu-button" onClick={toggleMenu}>
              <svg className={`w-12 h-12 text-white hover:text-rorange ${menuVisible ? "text-rorange" : ""}`}
                   x-show="!showMenu"
                   fill="none"
                   strokeLineCap="round"
                   strokeLineJoin="round"
                   strokeWidth="2"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        </div>
        <div className={`${menuVisible ? "visible" : ""} lg:hidden mobile-menu mt-[88px] bg-rdarkblue text-rgray z-20`}>
          <ul className="">
            {menu.map(({title, route}) => (<li className={location.pathname === route ? "active" : ""}><Link
                to={route}
                className="block px-2 py-4 transition duration-300"
                onClick={hideMenu}
              >
                {title}
              </Link></li>
            ))}
          </ul>
        </div>
      </nav>

      <main className="w-full items-center">
        {children}
      </main>

      <footer className="w-full
              left-0 bottom-0
              flex flex-col justify-center items-center
              mt-auto
              bg-black
              text-white
              text-xs
              ">
        <div className="p-5">
          © ROMALEX RND 2022-2024
        </div>
      </footer>
    </div>
  );
}

export default Layout;
