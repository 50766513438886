import Section from "../components/Section";

function Contact() {

  return (
      <Section
        breadcrumb="Contacts"
        header="Contact us"
        id="contacts"
      >
        <div className="text-left mb-12">
          <a href="mailt:info@romalex.uz">info@romalex.uz</a>
        </div>
      </Section>
  );
}

export default Contact;


