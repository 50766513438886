function TextSections({sections, className, mb="mb-6"}) {
  return <div>
    {sections.map((section, key)=>(<div className={
      key < (sections.length-1) ? mb : 'mb-0'
        + ' '
        + className}
    >{section}</div>))}
  </div>
}

export default TextSections
