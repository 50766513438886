import TextSections from "../../components/TextSections";
import SubSection from "../../components/SubSection";

const sections = [
  'Thousands of scientists and researchers, tens of thousands of professors, millions of applicants and students use daily platforms, systems, and applications that we have created to unlock human potential. Our customers are the largest educational institutions across the globe, including the USA, Canada, Great Britain, the EU, the UAE, China, Australia.',
  'Authors and researchers share and promote their works via our online publishing system in scientific magazines and libraries with 7.5M+ articles from 1,600+ journals and 21,000+ e-books.',
  '800K+ new students every year use our e-learning educational platform for gaining knowledge and passing the final exams on multiple disciplines, incl. Accounting, Physics, Chemistry, Management, Mathematics, Anatomy and Physiology, Geography, Nutrition, Foreign Languages.'
]

function OurClients() {
  return (
    <SubSection>
      <h2>Our Customers</h2>

      <div className="mt-4 lg:flex w-full">
        <div className="mr-10 flex-shrink-0"><img style={{width:400}} src="/img/pic_03.png" alt="clients"/></div>
          <div className="col-span-7">
          <TextSections sections={sections} mb="mb-4"/>
        </div>
      </div>
    </SubSection>
);
}

export default OurClients;
