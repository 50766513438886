import Section from "../components/Section";

import Development from "./subSections/Development";
import AgileMethod from "./subSections/AgileMethod";
import QA from "./subSections/QA";
import HowDoWeWork from "./subSections/HowDoWeWork";

function Services() {
  return (
    <Section header="Services" breadcrumb="Services" id="services">
      <Development/>
      <HowDoWeWork />
      <QA />
      <AgileMethod />
    </Section>
  );
}

export default Services;
