import TextSections from "../../components/TextSections";
import SubSection from "../../components/SubSection";
import WileyLink from "../../components/WileyLink";

const sections = [
  `We specialize in development of digital platforms and integrated systems for education, e-publishing, machine learning, content management.`,
  `Ingrained engineering culture, rich experience and the highest quality standards allow us to develop and deliver custom solutions as well as to incorporate into existing teams.`,
  `Our mission is to help our customers to succeed with our IT solutions.`,
  <div>Our team has been built on a basis of <WileyLink /> technological center
    in Russia. Since we have launched in 2002, we’ve been incredibly proud of helping Wiley in transformation from
    printed book publisher to technology company, world leader in electronic educational business.</div>,
  `We strive to apply our experience and expertise on a broader market and to provide innovative IT solution to our customers.`,
  `Our high-class specialists are a close-knit team, used to work for the result. Many years of experience in development, delivery, and maintenance of e-learning platforms can unlock the potential of our customers and partners.`
]

function AboutDetails() {
  return (
    <SubSection>
        <h1 className="mt-[140px]">ROMALEX is a licensed and accredited IT company, a resident of
          IT Park in the Republic of Uzbekistan.</h1>
        <div className="flex w-full justify-center">
        <img className="mt-[50px] mb-[55px] w-full max-w-[1164px]" src="/img/pic_02.png" alt="logo-people-near-office"/>
        </div>
        <TextSections sections={sections}/>
    </SubSection>
  );
}

export default AboutDetails;
