import About from './About'
import Services from "./Services";
import Clients from "./Clients";
import Contact from "./Contact";
import Activities from "./Activities";

function Home() {
  return (
    <>
      <div className="home-content">

      </div>
      <div className="flex">
        <div className="bg-rlightblue w-[100px] hidden lg:block">
        </div>
        <div className="w-full">
          <div className="lg:ml-[-100px]">
            <About/>
            <Services/>
            <Clients/>
            <Activities/>
            <Contact/>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
