import Advantage from "../../components/Advantage";
import SubSection from "../../components/SubSection";

function Advantages() {
  return (
    <SubSection>
      <h2>Our Advantages</h2>

      <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <Advantage
            width="80"
            height="80"
            icon="ic_01.svg"
            text="Professional team with IT expertise, incl. solution architects, business analysts, engineers, software developers."
          />
          <Advantage
            width="80"
            height="80"
            icon="ic_02.svg"
            text="20+ years of experience in development, delivery, and support of complete IT solutions."
          />
          <Advantage
            width="80"
            height="80"
            icon="ic_03.svg"
            text="Experience in organizing 24/7/365 technical support and trouble-free operation of applications and services."
          />
          <Advantage
            width="80"
            height="80"
            icon="ic_04.svg"
            text="Professional engineering and management culture allows to reduce contractor control."
          />
          <Advantage
            width="80"
            height="80"
            icon="ic_05.svg"
            text="Focus on and accountability for a result."
          />
      </div>

    </SubSection>
  );
}

export default Advantages;
