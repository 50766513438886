import CompetenciesBlock from "../../components/CompetenciesBlock";
import SubSection from "../../components/SubSection";

function Competencies() {
  return (
    <SubSection>
      <h2>Our Competencies</h2>

      <div className="flex justify-center">
        <div className="lg:w-2/3">
          <CompetenciesBlock
            header="1. Content Preparation and Publication Systems"
            items={[
              'Enhanced search of publishers and journals for your research',
              'Assistance in preparation of content materials and manuscripts',
              'Automatic selection of reviewers and creation of a review for your article',
              'Publication of your research in leading scientific journals'
            ]}
          />
          <CompetenciesBlock
            header="2.	E-learning Platforms"
            items={[
              'In-house development of Learning Management System and educational services',
              'Integration of e-learning services into external Learning Management Systems, e.g. Instructure Canvas, Moodle, Blackboard, D2L, etc.',
              'E-textbook Player and additional study materials in various multimedia formats',
              'Rich set/Wide choice of questions in test banks for homework, quizzes, midterm exams, final exams',
              'Automatic evaluation system with the support for 50+ question types, including Molecular Drawing, Math Symbolic Notation, Chem Symbolic Notation, Drag’n’Drop, Algorithmic Questions, Multistep Questions.',
              'Adaptive Assignments',
              'Class and Course Gradebook',
              'Out-of-the-box content production and content correction/update mechanism',
            ]}
          />
          <CompetenciesBlock
            header="3.	AI Laboratory - Applied Study of AI Technology"
            items={[
              'Generative and creative tools',
              'Recommendation systems',
              'Language processing',
              'Computer vision'
            ]}
          />
          <CompetenciesBlock
            header="4.	Design and development of IT solutions on demand"
            items={[
              'Multi-purpose web services, incl. the Internet and the Internet solutions',
              'b.Integrated solutions',
            ]}
          />
          <CompetenciesBlock
            header="5.	Microservice architecture and middleware solutions"
            items={[
              'High-load and failover solutions'
            ]}
          />
          <CompetenciesBlock
            header="6.	Business process automation"
            items={[

            ]}
          />
        </div>
      </div>

    </SubSection>
  );
}

export default Competencies;
