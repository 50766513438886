import SubSection from "../../components/SubSection";
import TextSections from "../../components/TextSections";

const sections1 = [
  'We are committed to Lean-Agile Principles of software development.',
]

const sections2 = [
'Our team develops IT products via Agile methodologies, such as Scrum, Kanban for smaller teams, SAFe, Less, Nexus for more complex projects.',
'We provide transparency of all processes and deliverables, active customer communication at all phases, regular system demonstrations, focus on the result. We are open for changes and are ready to adapt to changed / changing environment.',
'Courage, focus, commitment, respect, and openness are fundamental values in our activity.'
]

  function AgileMethod() {
  return (
    <SubSection className="mb-10">
      <h2>Agile Methodology</h2>
      <TextSections sections={sections1} className="mb-8 sub-header" />
      <TextSections sections={sections2} />
      <div className="w-full flex justify-center">
      <img src="/img/pic_05.png" alt="agile methodology" className="block mt-14"/>
      </div>
    </SubSection>
  );
}

export default AgileMethod;
