const menu = [
  {
    title: 'About',
    route: '/about'
  },
  {
    title: 'Services',
    route: '/services'
  },
  {
    title: 'Clients',
    route: '/clients'
  },
  {
    title: 'Activities',
    route: '/activities'
  },
  {
    title: 'Contacts',
    route: '/contacts'
  }
]

export default menu
