import Check from "./Check";

function KeyDirectionsBlock({number, items, header, className}) {
return  <div className={`key-directions-block  ${className ?? 'mb-8'}`}>
  <div className="grid grid-cols-12 text-sdarkgreen pt-8">
    <div className="hidden md:block key-directions-number ">{number}</div>
    <h4 className="col-span-10 ml-0 lg:ml-8 text-white">{header}</h4>
  </div>

  <div className="md:grid grid-cols-12 mt-4 pb-8 ">
    <div className="key-directions-number"></div>
    <div className="col-span-10 ml-0 lg:ml-8">
      {items.map(item => <Check key={item}>{item}</Check>)}
    </div>
  </div>
</div>
}

export default KeyDirectionsBlock
