function Advantage({icon, text, width, height}) {
  return <div className="mt-4">
    <div className="flex flex-col advantage lg:min-h-[290px]">
      <div className="flex flex-shrink-0 justify-center"><img style={{width: `${width}px`, height:`${height}px`}} src={`/img/${icon}`} alt=""/></div>
      <div className="text-center mt-2 text-base2">{text}</div>
    </div>

  </div>
}

export default Advantage
