import TextSections from "../../components/TextSections";
import SubSection from "../../components/SubSection";
import Check from "../../components/Check";

function QA() {
  return (
    <SubSection>
      <h2>Quality Assurance</h2>
      <div className="flex mb-[60px] justify-center">
        <img src='/img/pic_04.png' className="mt-4 mb-4" alt="qa"/>
      </div>
      <TextSections
        className="sub-header font-semibold"
        sections={['Testing allows to check actual and expected results of system behavior / feedback / working / performance / actions. We provide a built-in quality assurance mechanism at every development phase.']}/>
      <div className="flex mt-4 justify-center">
        <div className="lg:w-3/4">
          {[
            'Functional and performance QA with auto-tests.',
            'Unambiguous documentation of test cases and acceptance criteria.',
            'Active use of Jira, Testrail, Confluence, TestComplete, Selenium, JUnit, TestNG, Cypress and tools and frameworks for better effectiveness of QA.',
            'We manage the process and constantly interact with our partners. We collectively define basic milestones/periods/stages and agree on acceptance criteria for each delivery phase. We prepare Service Level Agreement (SLA) and provide monitoring results.',
            'We provide testing reports on regular basis.',
          ].map(item => <Check>{item}</Check>)}
        </div>
      </div>
    </SubSection>
  );
}

export default QA;
