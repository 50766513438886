import Section from "../components/Section";

import AboutDetails from "./subSections/AboutDetails";
import OurClients from "./subSections/OurClients";
import Competencies from "./subSections/Competencies";
import Advantages from "./subSections/Advantages";

function About() {
  return (
    <Section breadcrumb="About" id="about">
      <AboutDetails/>
      <OurClients/>
      <Competencies/>
      <Advantages/>
    </Section>
  );
}

export default About;
