function TextBlock({id, header, breadcrumb, children, className, contentClassname}) {
  return <div id={id ?? ''} className={`flex flex-col items-center justify-center mt-12 ${className ?? ''}`}>
    <div className={`w-11/12 md:w-[63%] ${contentClassname}`}>
      <h2>{header}</h2>
      {children}
    </div>
  </div>
}

export default TextBlock
