import SubSection from "../../components/SubSection";
import TextSections from "../../components/TextSections";

const sections = [
'Our specialists will work on architecture, technical solution, and design of your product. We will create or help to create a development plan and delivery strategy of end-to-end solution.',
'We will undertake management and development of comprehensive solution or will act as a contractor for development of building blocks or separate services.'
]

function Development() {
  return (
    <SubSection>
      <TextSections sections={sections} />
    </SubSection>
  );
}

export default Development;



