import Section from "../components/Section";

const activities = [
  {
    header: 'Research Libraries',
    text: 'Libraries and Databases for Research'
  },
  {
    header: 'Publishing Services',
    text: 'Publish Your Research'
  },
  {
    header: 'Academic Education Platforms',
    text: 'Improve the classroom experience with engaged student learning'
  },
  {
    header: 'AI Lab',
    text: 'Applied Study of AI Technology: generative and creative tools, recommendation systems, language processing, computer vision'
  },
  {
    header: 'HR Tools for Mid-size Companies',
    text: 'Vacation scheduler, Interactive ChatBot, Employee Management Services'
  },
  {
    header: 'eLearning Tools',
    text: 'Course Builder, Test Builder, Course Player'
  },
  {
    header: 'AI for Spine MRI Interpretations',
    text: 'Hybrid AI model to compute the probability of spinal surgical recommendations for Lumbar Spinal Stenosis'
  },
  {
    header: 'Customer Relationships Management Platform for HoReCa',
    text: 'Assistive CRM as a tool for businesses in the HoReCa industry, which includes hotels, restaurants, and cafes'
  }
]

function Activity({header, text}) {
  return <div className="mt-4">
    <div className="flex flex-col justify-top items-center advantage lg:min-h-[355px]">
      <h4 className="mt-4 mb-4 text-center sub-header text-rorange">{header}</h4>
      <div className="text-center">{text}</div>
    </div>
  </div>
}

function Services() {
  return (
    <Section breadcrumb="Activities" id="activities">
      <h2>Activities</h2>
      <div className="lg:grid lg:grid-cols-3 lg:gap-8">
        {activities.map(Activity)}
      </div>
    </Section>
  );
}

export default Services;
