import Check from "./Check";

function CompetenciesBlock({items, header}) {
  return <div className="mt-4">
    <h4>{header}</h4>
    <div className="md:ml-8">
      {items.map(item => <Check>{item}</Check>)}
    </div>
  </div>
}

export default CompetenciesBlock
