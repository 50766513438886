import SubSection from "../../components/SubSection";
import KeyDirectionsBlock from "../../components/KeyDirectionsBlock";

function HowDoWeWork() {
  return (
    <SubSection>
      <h3>How Do We Work</h3>
      <KeyDirectionsBlock
        number="1"
        header="Define technical tasks and target group."
        items={[
          'Аnalyze market needs.',
          'Agree on a business vision of a product.'
        ]}
      />
      <KeyDirectionsBlock
        number="2"
        header="Form partnership relations and responsibilities"
        items={[
          'Create a development team with a dedicated resource manager.',
          'Take care of including team members with required skills, knowledge, and expertise.',
          'Undertake partnership obligations, including formation of interaction model, implementation period and milestones / main stages / phases, maintenance during the operational phase.'
        ]}
      />
      <KeyDirectionsBlock
        number="3"
        header="Launch development."
        items={[
          'Build communication channels with the customer.',
          'Agree on development phases, project roadmap, delivery dates.',
          'Provide a smooth start.',
          'Support the development until achieving business goals.',
          'Provide progress transparency: daily updates on the start project increment, regular product demo furthermore.',
          'Project manager facilitates implementation of required changes and corrections into the process organization, customer communication, product delivery.',
          'Provide incremental delivery by small batches/quantities/parts with regular inspection of interim/intermediate results.',
          'Provide agility/flexibility in adapting to changes.'
        ]}
      />
      <KeyDirectionsBlock
        number="4"
        header="Agile strategic partnership."
        items={[
          'Adapt development team resources and skills if needed.',
          'Provide technical support after product launch.',
          'Ongoing improvement and enhancement of product functionality based on customer feedback and user activity monitoring.'
        ]}
      />
    </SubSection>
  );
}

export default HowDoWeWork;
