function Check({children, className}) {
  return <div
    className={`mt-4 grid grid-cols-12 ${className}`}
  >
    <div className="mr-2">
      <img alt="check" width="29" height="28" src="/img/ic_06.svg"/>
    </div>
    <div className="col-span-11 text-base2">
      {children}
    </div>
  </div>
}

export default Check
